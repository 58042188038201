import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fuel-tank-grid-item',
  templateUrl: './fuel-tank-grid-item.component.html',
  styleUrls: ['./fuel-tank-grid-item.component.scss']
})
export class FuelTankGridItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
