import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { NgControl, Validator } from '@angular/forms';
import { Location } from '@angular/common';
import {
    DefaultFormComponent,
    fadeInOut,
    fadeInOut2,
    FormServiceInterface,
    ModalsServiceInterface, NavItem,
    SfFormCollectionComponent,
    UserServiceInterface,
} from '@hutsix/ngxh6';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-fuel-system',
    templateUrl: './fuel-system.component.html',
    styleUrls: ['./fuel-system.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuelSystemComponent extends DefaultFormComponent {

    public navItems: NavItem[] = [
        { id: 'general', label: 'Fuel System', destroyOnHide: true },
        { id: 'grid', label: 'Fuel Layout Grid', destroyOnHide: true },
    ];
    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        public location: Location,
        public cdRef: ChangeDetectorRef,
        public toastr: ToastrService,
    ) {
        super(formService, userService, location, cdRef);
    }
}
