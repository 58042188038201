import { DefaultFilterComponent, DefaultFormComponent, FormOptions } from '@hutsix/ngxh6';
import { UserFormComponent } from '../modules/_shared/components/forms/custom-forms/user-form/user-form.component';
import forms_conf from '../_generated/ext_config/forms.json';
import { FuelSystemComponent } from '../modules/default/routes/platform/fuel-system/fuel-system.component';
import { FuelTankGridItemComponent } from '../modules/default/routes/platform/fuel-tank-grid-item/fuel-tank-grid-item.component';
export const custom_filters: { [key: string]: any } = {
  DefaultFilterComponent: DefaultFilterComponent,
};

export const custom_forms: { [key: string]: any } = {
  DefaultFormEmbedComponent: DefaultFormComponent,
  UserFormComponent: UserFormComponent,
  FuelSystemComponent: FuelSystemComponent,
  FuelTankGridItemComponent: FuelTankGridItemComponent,
};

/** Override generated form values here */
const forms_conf_overrides: Override = {
  fuel_system: {
    slug: 'fuel_system',
    object_name: 'Fuel System',
    object_class: 'App\\Entity\\Aircraft\\Fuel\\FuelSystem',
    api_refresh_endpoint: '/api/v1/fuel_systems',
    allow_archive: false,
    display: 'vertical',
    component: 'FuelSystemComponent',
  },
};

/** Create custom forms or override generated forms here */
const forms = {
  // example_test: new FormOptions({
  //   slug: 'profile',
  //   object_name: 'Example',
  //   display: 'horizontal-wide',
  //   create_string: 'Profile',
  // }),
  device: new FormOptions({
    object_name: 'Device',
    api_refresh_endpoint: '/api/v1/devices',
    offline_edit: false,
    offline_create: false,
    allow_archive: true,
    object_class: 'App\\Entity\\Device\\Device',
    success_route: 'devices/{id}',
  }),
  cg_envelope: new FormOptions({
    slug: 'cg_envelope',
    object_name: 'Envelope',
    object_class: 'App\\Entity\\Aircraft\\Utils\\CgEnvelope',
    api_refresh_endpoint: '/api/v1/cg_envelopes',
    api_get: '/api/aircraft/envelopes/cg',
    allow_archive: false,
    display: 'horizontal-wide',
  }),
  lateral_envelope_ground: new FormOptions({
    slug: 'lateral_envelope_ground',
    object_name: 'Envelope',
    object_class: 'App\\Entity\\Aircraft\\Utils\\CgEnvelope',
    api_refresh_endpoint: '/api/v1/cg_envelopes',
    api_get: '/api/aircraft/envelopes/lateral_ground',
    allow_archive: false,
    display: 'horizontal-wide',
  }),
  lateral_envelope_flight: new FormOptions({
    slug: 'lateral_envelope_flight',
    object_name: 'Envelope',
    object_class: 'App\\Entity\\Aircraft\\Utils\\CgEnvelope',
    api_refresh_endpoint: '/api/v1/cg_envelopes',
    api_get: '/api/aircraft/envelopes/lateral_flight',
    allow_archive: false,
    display: 'horizontal-wide',
  }),
  envelope_zero_fuel: new FormOptions({
    slug: 'envelope_zero_fuel',
    object_name: 'Envelope',
    object_class: 'App\\Entity\\Aircraft\\Utils\\CgEnvelope',
    api_refresh_endpoint: '/api/v1/cg_envelopes',
    api_get: '/api/aircraft/envelopes/zero_fuel',
    allow_archive: false,
    display: 'horizontal-wide',
  }),
  envelope_landing: new FormOptions({
    slug: 'envelope_landing',
    object_name: 'Envelope',
    object_class: 'App\\Entity\\Aircraft\\Utils\\CgEnvelope',
    api_refresh_endpoint: '/api/v1/cg_envelopes',
    api_get: '/api/aircraft/envelopes/landing',
    allow_archive: false,
    display: 'horizontal-wide',
  }),
  fuel_tank_grid_item: new FormOptions({
    slug: 'fuel_system',
    object_name: 'Fuel System',
    object_class: 'App\\Entity\\Aircraft\\Fuel\\FuelSystem',
    // api_refresh_endpoint: '/api/v1/fuel_systems',
    allow_archive: false,
    display: 'vertical',
    component: 'FuelTankGridItemComponent',
  }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getFormsConfig(): { [key: string]: FormOptions } {
  const f = JSON.parse(JSON.stringify(forms_conf));
  for (const [k, v] of Object.entries(forms_conf_overrides)) {
    if (f[k]) {
      f[k] = Object.assign(forms_conf[k], v);
    } else {
      console.warn(`Attempt to override generated form failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...f, ...forms };
}

export function getForm(slug: FormKeys): FormOptions {
  return getFormsConfig()[slug];
}

declare global {
  type FormKeys = keyof typeof forms | keyof typeof forms_conf;
}

type Override = { [Property in keyof Partial<typeof forms_conf>]: Partial<FormOptions> };
